import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl-next';
import { styled } from '@compiled/react';
import { Subscribe } from 'unstated';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button/standard-button';
import WatchFilledIcon from '@atlaskit/icon/glyph/watch-filled';
import WatchIcon from '@atlaskit/icon/glyph/watch';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import { SpotlightManager, SpotlightTarget } from '@atlaskit/onboarding';

import { MANAGE_WATCHER_TAB_TYPES } from '@confluence/manage-watcher-dialog';
import { SSRActionLoadingSpinner } from '@confluence/ssr-utilities';
import { SSRMouseEventWrapper, SSR_WATCH_BUTTON_METRIC } from '@confluence/browser-metrics';
import { ShortcutVisualizer, WATCH_CONTENT_SHORTCUT } from '@confluence/shortcuts';
import { HeaderItemsContainer } from '@confluence/header-items-manager';

import { WatchContentNudgeSpotlight } from './WatchContentNudgeSpotlight';

const i18n = defineMessages({
	startWatchingIconLabel: {
		id: 'watch-dialog.watch-button.start.watching.label',
		defaultMessage: 'Start watching',
		description: 'A label for the start watching space/page button',
	},
	stopWatchingIconLabel: {
		id: 'watch-dialog.watch-button.stop.watching.label',
		defaultMessage: 'Stop watching',
		description: 'A label for the stop watching space/page button',
	},
	watchStopTooltip: {
		id: 'watch-dialog.stop.button.tooltip',
		defaultMessage: 'Stop watching',
		description: 'Stop watching tooltip',
	},
	watchStartTooltip: {
		id: 'watch-dialog.start.button.tooltip',
		defaultMessage: 'Watch',
		description: 'Start Watching tooltip',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledButton = styled(Button)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		minWidth: '30px',
	},
});

const WatchButtonComponent = ({
	isDisabled,
	isWatching,
	isSelected,
	onClick,
	onMouseOver,
	intl,
	triggerProps,
	shouldSSRWatchButton,
	defaultTab,
	hasRecentlyUnwatchedContent,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		// on mount, immediately handle the SSR click since the
		// data from WatchDialogQuery has already been preloaded
		// Additionally, checking window.__SSR_EVENTS_CAPTURE will
		// only have attributes when SSRing View page
		if (shouldSSRWatchButton && window?.__SSR_EVENTS_CAPTURE__?.watchButton) {
			handleWatchButtonClick();
			delete window.__SSR_EVENTS_CAPTURE__.watchButton;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fireClickAnalyticsEvent = useCallback(() => {
		const analyticsData = {
			action: 'clicked',
			actionSubject: 'content-watch-button',
			source: 'viewPageScreen',
		};

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: analyticsData,
		}).fire();
	}, [createAnalyticsEvent]);

	const handleWatchButtonClick = () => {
		onClick();
		fireClickAnalyticsEvent();
	};

	const onDismissNudgeButtonClick = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'dismissed',
				actionSubject: 'button',
				actionSubjectId: 'WatchDialogWatchContentNudge',
				source: 'WatchButton',
			},
		}).fire();
	};

	const onWatchFromNudgeButtonClick = () => {
		onClick();
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'watched',
				actionSubject: 'button',
				actionSubjectId: 'WatchDialogWatchContentNudge',
				source: 'WatchButton',
			},
		}).fire();
	};

	const shouldSSRWatchButtonForViewPage = defaultTab === MANAGE_WATCHER_TAB_TYPES.PAGE;
	const shouldSSRWatchButtonForSOPage = defaultTab === MANAGE_WATCHER_TAB_TYPES.SPACE;

	return (
		<Subscribe to={[HeaderItemsContainer]}>
			{({ getAllItemsLoadState }) => {
				return (
					<SpotlightManager blanketIsTinted={false}>
						<SpotlightTarget name="content-watch-spotlight">
							<Tooltip
								tag="span"
								content={
									isDisabled ? undefined : (
										<ShortcutVisualizer
											shortcut={WATCH_CONTENT_SHORTCUT}
											contentBefore={
												isWatching
													? intl.formatMessage(i18n.watchStopTooltip)
													: intl.formatMessage(i18n.watchStartTooltip)
											}
										/>
									)
								}
							>
								{process.env.REACT_SSR &&
									(shouldSSRWatchButtonForViewPage || shouldSSRWatchButtonForSOPage) && (
										<SSRActionLoadingSpinner
											spinnerId="watch-loading-spinner"
											actionType="watchButton"
										/>
									)}
								<SSRMouseEventWrapper metricName={SSR_WATCH_BUTTON_METRIC}>
									<StyledButton
										data-id="page-watch-button"
										data-test-id="page-watch-button"
										testId="page-watch-button"
										appearance="subtle"
										isDisabled={isDisabled}
										isSelected={isSelected}
										onClick={handleWatchButtonClick}
										onMouseOver={onMouseOver}
										onFocus={onMouseOver}
										iconBefore={
											isWatching ? (
												<WatchFilledIcon
													label={intl.formatMessage(i18n.stopWatchingIconLabel)}
													testId="watch-filled-icon"
												/>
											) : (
												<WatchIcon
													label={intl.formatMessage(i18n.startWatchingIconLabel)}
													testId="watch-icon"
												/>
											)
										}
										{...triggerProps}
									/>
								</SSRMouseEventWrapper>
							</Tooltip>
						</SpotlightTarget>
						{getAllItemsLoadState() &&
							!isDisabled &&
							!isWatching &&
							!hasRecentlyUnwatchedContent && (
								<WatchContentNudgeSpotlight
									onWatchFromNudgeButtonClick={onWatchFromNudgeButtonClick}
									onDismissNudgeButtonClick={onDismissNudgeButtonClick}
								/>
							)}
					</SpotlightManager>
				);
			}}
		</Subscribe>
	);
};

WatchButtonComponent.propTypes = {
	isDisabled: PropTypes.bool,
	isWatching: PropTypes.bool.isRequired,
	isSelected: PropTypes.bool.isRequired,
	onClick: PropTypes.func.isRequired,
	onMouseOver: PropTypes.func,
	intl: PropTypes.object,
	triggerProps: PropTypes.object,
	shouldSSRWatchButton: PropTypes.bool,
	defaultTab: PropTypes.number,
	hasRecentlyUnwatchedContent: PropTypes.bool,
};

export const WatchButton = injectIntl(WatchButtonComponent);
