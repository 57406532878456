import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import { HeaderItemsContainer, HEADER_ITEM_KEYS } from '@confluence/header-items-manager';

import { WatchDialog } from './WatchDialog';

export class WatchDialogWrapper extends PureComponent {
	static propTypes = {
		contentId: PropTypes.string.isRequired,
		defaultTab: PropTypes.number.isRequired,
		componentLocation: PropTypes.number,
	};

	isItemMarked = false;
	markItem = null;

	buttonRendered = () => {
		if (!this.isItemMarked) {
			this.isItemMarked = true;
			this.markItem(HEADER_ITEM_KEYS.WATCH_PAGE);
		}
	};

	render() {
		const { contentId, defaultTab, componentLocation } = this.props;

		return (
			<Subscribe to={[HeaderItemsContainer]}>
				{(headerItemsContainer) => {
					const { addItem, markItem } = headerItemsContainer;
					addItem(HEADER_ITEM_KEYS.WATCH_PAGE);
					this.markItem = markItem;
					return (
						<WatchDialog
							contentId={contentId}
							defaultTab={defaultTab}
							customPlacement="bottom"
							onRender={this.buttonRendered}
							componentLocation={componentLocation}
						/>
					);
				}}
			</Subscribe>
		);
	}
}
